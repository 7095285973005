import {
  Box,
} from "../../../components/StyledComponent";
import DESKTOP_BG from "../../../assets/images/Parabellum desktop.png";
import MOBILE_BG from "../../../assets/images/Parabellum mobile.png";
import styled from "styled-components";
// import { isMobile } from "../../../constants";

const Showcase = () => {
  const handleClick = () => {
    window.location.assign('https://parabellum.com/');
  }
  return (
    <Box
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden py-10"
    >
      <img src={DESKTOP_BG} className="h-screen hidden md:block" />
      <img src={MOBILE_BG} className="h-screen block md:hidden" />
      <button className="absolute mt-[16rem] md:mt-[20rem] 3xl:mt-[23rem] text-lg md:text-2xl" onClick={handleClick}>Click To Enter</button>
    </Box>
  );
};

export default Showcase;
